<template>
  <div>
    <b-overlay
        :show="tabsLoading"
        spinner-variant="primary"
        spinner-type="grow"
        rounded="sm"
    >
      <b-card-code
          title="Foglalás"
      >
        <b-row>
          <b-col md="4">
            {{ formData.fishingSpot.name }}
          </b-col>

          <b-col md="4">
            Reg. sz. {{ formData.regNum.substring(formData.regNum.length - 3) }}.
          </b-col>

          <b-col md="4">
            {{ formData.startDate.format('YYYY. MMM. DD.').toLowerCase() }} -
            {{ formData.endDate.format('YYYY. MMM. DD.').toLowerCase() }}
          </b-col>
        </b-row>
      </b-card-code>

      <b-card-code
          title="Státusz"
      >
        <b-row>
          <b-col md="12">
            <span v-html="formData.statusDescription"></span>
          </b-col>

          <b-col class="mt-1" md="12">
            <b-button v-if="formData.status === 'request'"
                      class="mr-1"
                      variant="secondary"
                      @click.prevent="conditionalConfirmReservation"
            >
              Feltételes jóváhagyás
            </b-button>
            <b-button v-if="formData.status === 'request' || formData.status === 'conditional'"
                      class="mr-1"
                      variant="primary"
                      @click.prevent="confirmReservation"
            >
              Jóváhagyás
            </b-button>
            <b-button
                class="mr-1"
                variant="info"
                @click.prevent="printReservation"
            >
              Nyomtatás
            </b-button>
            <b-button
                v-if="formData.status === 'request' || formData.status === 'conditional' || formData.status === 'confirmed'"
                class="mr-1"
                variant="warning"
                @click.prevent="editReservation"
            >
              Módosítás
            </b-button>
            <b-button
                v-if="formData.status === 'request' || formData.status === 'conditional' || formData.status === 'confirmed'"
                class="mr-1"
                variant="danger"
                @click.prevent="deleteReservation"
            >
              Törlés
            </b-button>
          </b-col>
        </b-row>
      </b-card-code>

      <b-card-code
          title="Kapcsolattartó"
      >
        <b-row>
          <b-col md="12">
            <b-media>
              <h5 class="mt-0">
                Név
              </h5>
              <b-card-text>
                {{ formData.name }}
              </b-card-text>

              <h5 class="mt-1">
                Email cím
              </h5>
              <b-card-text>
                {{ formData.email }}
              </b-card-text>

              <h5 class="mt-1">
                Telefonszám
              </h5>
              <b-card-text>
                {{ formData.phone }}
              </b-card-text>

              <h5 class="mt-1">
                Ország
              </h5>
              <b-card-text>
                {{ nationalities.getName(formData.country, "hu") }}
              </b-card-text>

              <h5 class="mt-1">
                Irányítószám
              </h5>
              <b-card-text>
                {{ formData.zip }}
              </b-card-text>

              <h5 class="mt-1">
                Település
              </h5>
              <b-card-text>
                {{ formData.city }}
              </b-card-text>

              <h5 class="mt-1">
                Cím
              </h5>
              <b-card-text>
                {{ formData.address1 }}
              </b-card-text>

              <h5 class="mt-1">
                Cím kiegészítés
              </h5>
              <b-card-text>
                {{ (formData.address2) ? formData.address2 : '-' }}
              </b-card-text>
            </b-media>
          </b-col>
        </b-row>
      </b-card-code>

      <b-card-code
          title="Részletek"
      >
        <b-row>
          <b-col md="12">
            <b-media>
              <h5 class="mt-0">
                Érkezés
              </h5>
              <b-card-text>
                {{ formData.startDate.format('YYYY. MMM. DD.').toLowerCase() }}, {{ formData.plannedArrival }}
              </b-card-text>

              <h5 class="mt-1">
                Távozás
              </h5>
              <b-card-text>
                {{ formData.endDate.format('YYYY. MMM. DD.').toLowerCase() }}, {{ formData.plannedDeparture }}
              </b-card-text>
            </b-media>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="12">

            <b-media>
              <h5 class="mt-2">
                Résztvevők
              </h5>
              <b-card-text>

                <b-row>
                  <b-col sm="12" md="6">Résztvevők összesen</b-col>
                  <b-col sm="12" md="4">
                    {{ formData.participantsInTotal }} fő
                  </b-col>
                  <b-col sm="12" md="2">
                    {{ $formatters.formatPriceText(getPrice('inTotal')) }} Ft
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="12" md="6">Felnőtt és ifi horgászok</b-col>
                  <b-col sm="12" md="6">
                    {{ formData.participantsAdultYoungster }} fő
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="participants-level-1" sm="12" md="6">Vendéghorgász</b-col>
                  <b-col sm="12" md="4">
                    {{ formData.participantsGuestAll }} fő
                  </b-col>
                  <b-col sm="12" md="2">
                    {{ $formatters.formatPriceText(getPrice('guestAll')) }} Ft
                  </b-col>
                </b-row>

                <b-row>
                  <b-col class="participants-level-2" sm="12" md="6">ebből ifi</b-col>
                  <b-col sm="12" md="4">
                    {{ formData.participantsGuestYoungster }} fő
                  </b-col>
                  <b-col sm="12" md="2">
                    {{ $formatters.formatPriceText(getPrice('guestYoungster')) }} Ft
                  </b-col>
                </b-row>

                <b-row>
                  <b-col class="participants-level-2" sm="12" md="6">ebből 70 év feletti</b-col>
                  <b-col sm="12" md="4">
                    {{ formData.participantsGuestOlderThan70Years }} fő
                  </b-col>
                  <b-col sm="12" md="2">
                    {{ $formatters.formatPriceText(getPrice('guestOlderThan70Years')) }} Ft
                  </b-col>
                </b-row>

                <b-row>
                  <b-col class="participants-level-2" sm="12" md="6">ebből hölgy</b-col>
                  <b-col sm="12" md="4">
                    {{ formData.participantsGuestLady }} fő
                  </b-col>
                  <b-col sm="12" md="2">
                    {{ $formatters.formatPriceText(getPrice('guestLady')) }} Ft
                  </b-col>
                </b-row>

                <b-row>
                  <b-col class="participants-level-2" sm="12" md="6">állami horgászjegyet váltana</b-col>
                  <b-col sm="12" md="4">
                    {{ formData.participantsGuestWantsStateTicket }} fő
                  </b-col>
                  <b-col sm="12" md="2">
                    {{ $formatters.formatPriceText(getPrice('guestWantsStateTicket')) }} Ft
                  </b-col>
                </b-row>

                <b-row>
                  <b-col class="participants-level-1" sm="12" md="6">BSHE alaptag</b-col>
                  <b-col sm="12" md="4">
                    {{ formData.participantsBSHEBasicMember }} fő
                  </b-col>
                  <b-col sm="12" md="2">
                    {{ $formatters.formatPriceText(getPrice('BSHEBasicMember')) }} Ft
                  </b-col>
                </b-row>

                <b-row>
                  <b-col class="participants-level-1" sm="12" md="6">BSHE teljes jogú tag</b-col>
                  <b-col sm="12" md="4">
                    {{ formData.participantsBSHEFullMember }} fő
                  </b-col>
                  <b-col sm="12" md="2">
                    {{ $formatters.formatPriceText(getPrice('BSHEFullMember')) }} Ft
                  </b-col>
                </b-row>

                <b-row>
                  <b-col sm="12" md="6">Gyermek horgászok</b-col>
                  <b-col sm="12" md="4">
                    {{ formData.participantsChild }} fő
                  </b-col>
                  <b-col sm="12" md="2">
                    {{ $formatters.formatPriceText(getPrice('child')) }} Ft
                  </b-col>
                </b-row>

                <b-row>
                  <b-col class="participants-level-2" sm="12" md="6">területi engedélyt váltana</b-col>
                  <b-col sm="12" md="4">
                    {{ formData.participantsChildWantsRegionalTicket }} fő
                  </b-col>
                  <b-col sm="12" md="2">
                    {{ $formatters.formatPriceText(getPrice('childWantsRegionalTicket')) }} Ft
                  </b-col>
                </b-row>

                <b-row>
                  <b-col sm="12" md="6">Kísérők</b-col>
                  <b-col sm="12" md="4">
                    {{ formData.participantsAttendant }} fő
                  </b-col>
                  <b-col sm="12" md="2">
                    {{ $formatters.formatPriceText(getPrice('attendant')) }} Ft
                  </b-col>
                </b-row>

              </b-card-text>

              <h5 class="mt-2">
                Opciók
              </h5>
              <b-card-text>
                <b-row>
                  <b-col sm="12" md="6">Opciók összesen</b-col>
                  <b-col sm="12" md="4">&nbsp;</b-col>
                  <b-col sm="12" md="2">{{ getPricingOption('sum').grossPrice }}
                  </b-col>
                </b-row>

                <b-row v-if="formData.fishingSpot.can_use_general_fishing_method">
                  <b-col sm="12" md="6">Horgászmódszer</b-col>
                  <b-col sm="12" md="4">
                    {{ getPricingOption('fishingMethod').value }}
                  </b-col>
                  <b-col sm="12" md="2">
                    {{ $formatters.formatPriceText(getPricingOption('fishingMethod').grossPrice) }} Ft
                  </b-col>
                </b-row>

                <b-row v-if="formData.fishingSpot.can_use_boat">
                  <b-col sm="12" md="6">Csónak etetéshez</b-col>
                  <b-col sm="12" md="4">
                    {{ (getPricingOption('boatForFeeding').value === 'yes') ? 'Igen' : 'Nem' }}
                  </b-col>
                  <b-col sm="12" md="2">
                    {{ $formatters.formatPriceText(getPricingOption('boatForFeeding').grossPrice) }} Ft
                  </b-col>
                </b-row>

                <b-row v-if="formData.fishingSpot.has_power_consumption">
                  <b-col sm="12" md="6">Áramfelvétel</b-col>
                  <b-col sm="12" md="4">
                    {{ (getPricingOption('powerConsumption').value === 'yes') ? 'Igen' : 'Nem' }}
                  </b-col>
                  <b-col sm="12" md="2">
                    {{ $formatters.formatPriceText(getPricingOption('powerConsumption').grossPrice) }} Ft
                  </b-col>
                </b-row>

                <b-row v-if="formData.fishingSpot.has_parking_fee">
                  <b-col sm="12" md="6">Gépjármű belépő</b-col>
                  <b-col sm="12" md="4">
                    {{ getPricingOption('vehicleEntranceFee').value }} db
                  </b-col>
                  <b-col sm="12" md="2">
                    {{ $formatters.formatPriceText(getPricingOption('vehicleEntranceFee').grossPrice) }} Ft
                  </b-col>
                </b-row>

                <b-row v-if="formData.fishingSpot.can_use_caravan">
                  <b-col sm="12" md="6">Lakókocsi belépő</b-col>
                  <b-col sm="12" md="4">
                    {{ getPricingOption('caravanEntranceFee').value }} db
                  </b-col>
                  <b-col sm="12" md="2">
                    {{ $formatters.formatPriceText(getPricingOption('caravanEntranceFee').grossPrice) }} Ft
                  </b-col>
                </b-row>

                <b-row>
                  <b-col sm="12" md="6">Egyéb közlemény (helyszíni kapcsolattartó neve, telefonszáma és
                    kapcsolódó foglalások jelölése)
                  </b-col>
                  <b-col sm="12" md="6">
                    <span v-if="formData.userComment" v-html="formData.userComment"></span>
                    <span v-else>-</span>
                  </b-col>
                </b-row>
              </b-card-text>

              <h5 class="mt-2">
                Összesítés
              </h5>
              <b-card-text>
                <b-row v-if="formData.adminComment && formData.adminComment !== ''">
                  <b-col sm="12" md="6">Egyéb leírás</b-col>
                  <b-col sm="12" md="6">
                    <span v-html="formData.adminComment"></span>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="12" md="6">Minimálisan fizetendő díj</b-col>
                  <b-col sm="12" md="4">
                    {{ $formatters.formatPriceText(formData.minGrossTotal) }} Ft
                  </b-col>
                  <b-col sm="12" md="2">
                    {{ (parseFloat(getPrice('inTotal')) > parseFloat(formData.minGrossTotal)) ? '0 Ft' : $formatters.formatPriceText(parseFloat(formData.minGrossTotal)-parseFloat(getPrice('inTotal')))+' Ft' }}
                  </b-col>
                </b-row>

                <b-row v-if="parseFloat(getPrice('other')) > 0">
                  <b-col sm="12" md="6">Egyéb díj</b-col>
                  <b-col sm="12" md="4">&nbsp;</b-col>
                  <b-col sm="12" md="2">
                    {{ $formatters.formatPriceText(getPrice('other')) }} Ft
                  </b-col>
                </b-row>

                <b-row>
                  <b-col sm="12" md="6">Foglalás összesen</b-col>
                  <b-col sm="12" md="4">
                    &nbsp;
                  </b-col>
                  <b-col sm="12" md="2">
                    {{ $formatters.formatPriceText(formData.grossTotal) }} Ft
                  </b-col>
                </b-row>
              </b-card-text>

            </b-media>
          </b-col>
        </b-row>
      </b-card-code>

    </b-overlay>

    <b-overlay
        :show="tabsLoading"
        spinner-variant="primary"
        spinner-type="grow"
        rounded="sm"
    >
      <b-card-code>
        <tabs
            ref="tabs"
            @refresh-tabs-data="refreshTabsData"
            :entity-id="parseInt(this.$route.params.id)"
        />
      </b-card-code>
    </b-overlay>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BOverlay, BRow, BCol, BButton, BMedia, BCardText
} from 'bootstrap-vue'
import tabs from '@/views/pages/_components/_tabs/_reservation/update.vue'
import cancelAndGOTOPreviousPage from '@/views/pages/_components/_helper/cancelAndGOTOPreviousPage'
import moment from 'moment'

export default {
  components: {
    BCardCode,
    BOverlay,
    BRow,
    BCol,
    BButton,
    BMedia,
    BCardText,
    tabs,
    cancelAndGOTOPreviousPage
  },
  data() {
    return {
      role: 'reservation',
      tabsLoading: false,
      formData: {
        id: '',
        fishingSpot: '',
        regNum: '',
        status: '',
        translatedStatus: '',
        statusDescription: '',
        name: '',
        email: '',
        phone: '',
        country: '',
        zip: '',
        city: '',
        address1: '',
        address2: '',
        startDate: moment(new Date()),
        endDate: moment(new Date()),
        plannedArrival: '',
        plannedDeparture: '',
        participantsInTotal: '',
        participantsAdultYoungster: '',
        participantsGuestAll: '',
        participantsGuestYoungster: '',
        participantsGuestOlderThan70Years: '',
        participantsGuestLady: '',
        participantsGuestWantsStateTicket: '',
        participantsBSHEBasicMember: '',
        participantsBSHEFullMember: '',
        participantsChild: '',
        participantsChildWantsRegionalTicket: '',
        participantsAttendant: '',
        userComment: '',
        adminComment: '',
        minGrossTotal: 0,
        grossTotal: 0,
        billingItems: [],
        pricingOptions: [],
      },
      baseUrl: process.env.VUE_APP_SERVER_BASE_URL,
      pricingOptions: [],
      nationalities: '',
    }
  },
  created() {
    let nationalities = require("i18n-iso-countries")
    nationalities.registerLocale(require("i18n-iso-countries/langs/hu.json"));
    this.nationalities = nationalities

    this.initEntity()
  },
  methods: {
    initEntity(onlyTabs = false) {
      this.$store.dispatch('fetchReservation', this.$route.params.id).then(response => {
        this.processEntity(response.data.entity, onlyTabs)
      }).finally(() => {
        this.$store.dispatch('fetchPricingOptions').then(pricingOptionRes => {

          let sum = 0;

          Object.keys(pricingOptionRes.data.options).forEach(remoteKey => {
            Object.keys(this.formData.pricingOptions).forEach(localKey => {
              if(pricingOptionRes.data.options[remoteKey].id === this.formData.pricingOptions[localKey].pricing_option_id){
                this.pricingOptions.push({
                  key: pricingOptionRes.data.options[remoteKey].key,
                  value: this.formData.pricingOptions[localKey].value,
                  grossPrice: this.formData.pricingOptions[localKey].gross_total,
                })

                sum = parseFloat(sum)+parseFloat(this.formData.pricingOptions[localKey].gross_total)
              }
            })
          })

          this.pricingOptions.push({
            key: 'sum',
            grossPrice: this.$formatters.formatPriceText(sum)+" Ft",
          })
        })
      })
    },
    processEntity(entity, onlyTabs = false) {
      this.tabsLoading = true;

      if (!onlyTabs) {

        this.formData.id = this.$route.params.id
        this.formData.fishingSpot = entity.fishing_spot
        this.formData.regNum = entity.reg_num
        this.formData.status = entity.status
        this.formData.translatedStatus = entity.translatedStatus
        this.formData.statusDescription = entity.statusDescription
        this.formData.name = entity.name
        this.formData.email = entity.email
        this.formData.phone = entity.phone
        this.formData.country = entity.country
        this.formData.zip = entity.zip
        this.formData.city = entity.city
        this.formData.address1 = entity.address_1
        this.formData.address2 = entity.address_2
        this.formData.startDate = this.$helpers.getDate(entity.start_date)
        this.formData.endDate = this.$helpers.getDate(entity.end_date)
        this.formData.plannedArrival = entity.planned_arrival
        this.formData.plannedDeparture = entity.planned_departure
        this.formData.userComment = entity.user_comment
        this.formData.adminComment = entity.admin_comment
        this.formData.minGrossTotal = entity.min_gross_total
        this.formData.grossTotal = entity.gross_total

        this.formData.participantsInTotal = entity.participants_in_total
        this.formData.participantsAdultYoungster = entity.participants_adult_youngster
        this.formData.participantsGuestAll = entity.participants_guest_all
        this.formData.participantsGuestYoungster = entity.participants_guest_youngster
        this.formData.participantsGuestOlderThan70Years = entity.participants_guest_older_than_70_years
        this.formData.participantsGuestLady = entity.participants_guest_lady
        this.formData.participantsGuestWantsStateTicket = entity.participants_guest_wants_state_ticket
        this.formData.participantsBSHEBasicMember = entity.participants_BSHE_basic_member
        this.formData.participantsBSHEFullMember = entity.participants_BSHE_full_member
        this.formData.participantsChild = entity.participants_child
        this.formData.participantsChildWantsRegionalTicket = entity.participants_child_wants_regional_ticket
        this.formData.participantsAttendant = entity.participants_attendant

        this.formData.billingItems = entity.billing_items
        this.formData.pricingOptions = entity.pricing_options
      }

      this.tabsLoading = false;
    },
    refreshTabsData() {
      this.$refs.tabs.refreshLog()
    },
    getPrice(pricingKey) {

      let price = 0;

      Object.keys(this.formData.billingItems).forEach(key => {
        if (pricingKey === this.formData.billingItems[key].key) {
          price = this.formData.billingItems[key].gross_total
        }
      })

      return price;
    },
    getPricingOption(pricingOptionKey){
      let pricingOption = '';

      Object.keys(this.pricingOptions).forEach(key => {
        if(pricingOptionKey === this.pricingOptions[key].key){
          pricingOption = this.pricingOptions[key];
        }
      })

      return pricingOption;
    },
    conditionalConfirmReservation() {
      this.$bvModal
          .msgBoxConfirm('Biztosan feltételesen jóváhagyod a foglalást?', {
            title: 'Jóváhagyás',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Feltételes jóváhagyás',
            cancelTitle: 'Mégsem',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              this.$store.dispatch('setReservationStatus', {id: this.formData.id, status: 'conditional'}).then(() => {
                this.$acl.canListPermission(this.role)
                    ? this.$router.push({name: 'reservations'})
                    : this.$router.go(-1)
                this.$helpers.showSuccessToast(`Sikeres státuszmódosítás (${this.formData.id})`)
              })
            }
          })
    },
    confirmReservation() {
      this.$bvModal
          .msgBoxConfirm('Biztosan jóváhagyod a foglalást?', {
            title: 'Jóváhagyás',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Jóváhagyás',
            cancelTitle: 'Mégsem',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              this.$store.dispatch('setReservationStatus', {id: this.formData.id, status: 'confirmed'}).then(() => {
                this.$acl.canListPermission(this.role)
                    ? this.$router.push({name: 'reservations'})
                    : this.$router.go(-1)
                this.$helpers.showSuccessToast(`Sikeres státuszmódosítás (${this.formData.id})`)
              })
            }
          })
    },
    editReservation() {
      if (this.$acl.canEditPermission(this.role))
        this.$router.push({name: 'modify-reservation', params: {id: this.formData.id}})
    },
    deleteReservation() {
      this.$bvModal
          .msgBoxConfirm('Biztosan törölni szeretnéd a foglalást?', {
            title: 'Jóváhagyás',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Törlés',
            cancelTitle: 'Mégsem',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              this.$store.dispatch('setReservationStatus', {id: this.formData.id, status: 'deleted'}).then(() => {
                this.$acl.canListPermission(this.role)
                    ? this.$router.push({name: 'reservations'})
                    : this.$router.go(-1)
                this.$helpers.showSuccessToast(`Sikeres státuszmódosítás (${this.formData.id})`)
              })
            }
          })
    },
    printReservation() {
      this.loading = true
      this.$store.dispatch('printReservation', this.formData.id).then(response => {
        const blob = new Blob([response.data], {type: 'application/pdf'})
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = this.$helpers.slug(this.formData.regNum)+'-visszaigazolas.pdf'
        link.click()
        URL.revokeObjectURL(link.href)

        this.$helpers.showSuccessToast()
      }).catch(error => {
        this.$helpers.showErrorToast(error.toString())
      }).finally(() => {
        this.loading = false
      })
      //window.open(this.baseUrl + '/reservation/' + this.formData.id, '_blank').focus();
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style>
.participants-level-1 {
  padding-left: 50px;
}

.participants-level-2 {
  padding-left: 100px;
}
</style>
